import React, {useContext, useEffect} from 'react';
import {Context} from '../../AppContext';
import UnAuthResult from '../../Components/UnAuthResult';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import Layout from '../../Components/ProfileLayout';
import MonthlyTable from './MonthlyTable';
import ORDER_TABLE_TYPES from '../../Components/OrderTable/orderTableTypes';
import LargeScreenTable from '../../Components/OrderTable/LargeScreenTable';
import SmallScreenTable from '../../Components/OrderTable/SmallScreenTable';
import useDimensions from '../../hooks/use-dimension';
import {Row, Col} from 'antd';
import useOrderTable from '../../hooks/use-order-table';
const qs = require('query-string');
const appConfig = require('../../data.json');

export default function MonthlyManage(props) {
  const app = useContext(Context);
  const pathname = props.location.pathname;
  const query = qs.parse(props.location.search);
  const {loading} = app.state;
  const {dimension} = useDimensions();
  const {orders, columns, filters, total, changeFilters} = useOrderTable({
    pathname: props.location.pathname,
    query,
    defaultType: ORDER_TABLE_TYPES.CURRENT_MONTHLY,
  });

  if (!app.state.profile) {
    return <UnAuthResult />;
  }

  return (
    <ContentWrapper>
      <Layout pathname={pathname} tabName={query.tabName}>
        {({tab}) => {
          return tab.name === 'currentPeriodOrder' ? (
            <div>
              {dimension.innerWidth > appConfig.breakpoints.sm ? (
                <LargeScreenTable
                  loading={loading}
                  orders={orders}
                  columns={columns}
                  filters={filters}
                  setFilters={changeFilters}
                  total={total}
                />
              ) : (
                <SmallScreenTable
                  loading={loading}
                  orders={orders}
                  columns={columns}
                  filters={filters}
                  setFilters={changeFilters}
                  total={total}
                />
              )}
            </div>
          ) : tab.name === 'monthlyRecord' ? (
            <MonthlyTable />
          ) : null;
        }}
      </Layout>
    </ContentWrapper>
  );
}

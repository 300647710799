import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import Image from '../../Components/Image';
import {Table, message} from 'antd';
import {Context} from '../../AppContext';
import {Link, Tag, Text} from '../../Widgets';
import {dateUtil} from '../../Utils/';
import {MONTHLY_STATUS, MONTHLY_ORDER_STATUS_COLOR} from '../../dictionary';
const appConfig = require('../../data.json');

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function MonthlyOrder(props) {
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({
    ...PAGINATION_INIT,
  });

  const app = useContext(Context);
  const {loading} = app.state;

  useEffect(() => {
    const {pageSize, current} = filters;

    (async () => {
      try {
        app.actions.setLoading(true);
        const {results, count} = await app.actions.getMonthlyOrders({
          offset: (current - 1) * pageSize,
          limit: pageSize,
          ordering: '-created',
        });
        setRecords(results);
        setTotal(count);
      } catch (err) {
        console.warn(err);
        message.error('取得月結記錄錯誤');
      }
      app.actions.setLoading(false);
    })();
  }, [app.actions.getMonthlyOrders]);

  const columns = useMemo(() => {
    return [
      {
        title: '對帳單編號',
        key: 'id',
        dataIndex: 'id',
        render: (id) => (
          <Link to={`/monthly?id=${id}`}>
            <Text size="sm" color={appConfig.colors.main}>
              {id}
            </Text>
          </Link>
        ),
      },
      {
        title: '繳費狀態',
        key: 'payment_status',
        dataIndex: 'payment_status',
        render: (value) => (
          <Tag
            color={MONTHLY_ORDER_STATUS_COLOR[value]}
            textColor="#fff"
            text={MONTHLY_STATUS[value]}
            style={{borderRadius: 6}}
          />
        ),
      },
      {
        title: '結算區間',
        key: 'period_from',
        render: (_, instance) =>
          instance.period_from + ' ～ ' + instance.period_to,
      },
      {
        title: '當期訂單數',
        key: 'order_count',
        render: (_, instance) => `${instance.order_count || '-'}筆`,
      },

      {
        title: '當期消費總金額',
        key: 'amount',
        dataIndex: 'amount',
        render: (value) => value + '元',
      },
      {
        title: '建立時間',
        render: (_, instance) => instance.created.slice(0, 10),
      },
      {
        title: '付款期限',
        key: 'pay_deadline',
        dataIndex: 'pay_deadline',
        render: (value) => dateUtil.format(value),
      },
    ];
  }, []);

  return (
    <Wrapper>
      <Table
        locale={{
          emptyText: (
            <Image
              src="/images/empty-order.svg"
              height="80"
              width="80"
              alt="empty order"
            />
          ),
        }}
        loading={loading}
        columns={columns}
        dataSource={records}
        rowKey="id"
        pagination={{
          pageSize: filters.pageSize,
          current: filters.current,
          total,
        }}
        onChange={(pagination) =>
          setFilters((prev) => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
          }))
        }
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .ant-table-content {
    border: solid 1px #ccc;
    border-radius: 4px;
  }
  & .ant-table-thead > tr > th {
    background-color: #fafafa;
    color: #505050;
    border-bottom: solid 1px #ccc;
  }
  & .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 15px 15px;
  }
`;
